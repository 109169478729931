@keyframes logo {
  0% {
    fill: #888;
  }
  40% {
    fill: #fff;
  }
  80% {
    fill: #888;
  }
  100% {
    fill: #888;
  }
}
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: #212529;
  text-align: left;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.h2,
h2 {
  font-size: 2rem;
}
.h3,
h3 {
  font-size: 1.75rem;
}
.h5,
h5 {
  font-size: 1.25rem;
  margin-top: 40px;
  border-bottom: 1px solid #ccc;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  text-decoration: underline;
}
.badge {
  display: inline-block;
  padding: 10px 15px;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #4b555c;
  margin: 10px 10px 10px 0;
}
.text-muted {
  color: #6c757d !important;
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
code.hljs {
  color: #000;
  border-radius: 4px;
  display: block;
  font-size: 13px;
  margin: 5px 0;
  overflow-x: auto;
  padding: 15px;
  text-align: left;
  white-space: pre;
  line-height: 1.42857143;
  background: #fff;
  border: 1px solid #ccc;
}
.hljs-attr {
  color: #0451a5;
}
.hljs-number {
  color: #09885a;
}
.hljs-string {
  color: #a31515;
}
.jst-container {
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.jst-sector {
  padding: 20px 0;
  min-height: 200px;
  background: #f7f7f7;
}
.jst-sector.task {
  background: #16111c;
  color: #999;
  border-bottom: 1px solid #555c62;
}
.jst-sector.task h2 {
  color: #ccc;
}
.jst-sector.demo {
  background-image: url(/img/back.png);
  background-color: #555c62;
  border-top: 1px solid #1d2124;
}
.jst-sector.demo .ruler {
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.jst-sector.demo .ruler > div {
  height: 100%;
  border-radius: 15px;
  position: absolute;
  width: 100%;
  text-align: right;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0 15px;
  color: #fff;
  cursor: pointer;
  box-sizing: border-box;
  border-left: 3px solid #1d2124;
  border-right: 3px solid #1d2124;
}
.jst-sector.demo .ruler > div.active {
  border-color: #1d2124;
}
.jst-sector.demo .ruler > div:before {
  content: "";
  flex: 1 1 auto;
  text-align: left;
}
.jst-sector.demo .ruler > div:after {
  content: "";
}
.jst-sector.demo .ruler > div.variant_1230 {
  background: #16111c;
  max-width: 1230px;
  border-color: #221b2c;
}
.jst-sector.demo .ruler > div.variant_1230:before,
.jst-sector.demo .ruler > div.variant_1230:after {
  content: "1230 px";
}
.jst-sector.demo .ruler > div.variant_960 {
  background: #2f243c;
  border-color: #3b2e4c;
  max-width: 960px;
}
.jst-sector.demo .ruler > div.variant_960:before,
.jst-sector.demo .ruler > div.variant_960:after {
  content: "960 px";
}
.jst-sector.demo .ruler > div.variant_690 {
  background: #48385b;
  border-color: #54416b;
  max-width: 690px;
}
.jst-sector.demo .ruler > div.variant_690:before,
.jst-sector.demo .ruler > div.variant_690:after {
  content: "690 px";
}
.jst-sector.demo .ruler > div.variant_320 {
  background: #614b7b;
  border-color: #6d548b;
  max-width: 320px;
}
.jst-sector.demo .ruler > div.variant_320:before,
.jst-sector.demo .ruler > div.variant_320:after {
  content: "320 px";
}
.jst-sector.demo .ruler > div:hover {
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.3);
}
.jst-sector.demo #monitor {
  max-width: 100%;
  transition: max-width 3s;
  margin: 0 auto;
  background: #110d16;
  padding: 5px 5px 8px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  text-align: right;
}
.jst-sector.demo #monitor .actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.jst-sector.demo #monitor .actions .open,
.jst-sector.demo #monitor .actions .refresh {
  padding: 7px 5px;
  display: inline-block;
  fill: #999;
  cursor: pointer;
}
.jst-sector.demo #monitor .actions .open:hover,
.jst-sector.demo #monitor .actions .refresh:hover {
  fill: #1c94d2;
}
.jst-sector.demo #monitor iframe {
  width: 100%;
  background: #fff;
  height: 500px;
  border: none;
  border-radius: 3px;
}
.jst-sector.demo #monitor.size_1230 {
  max-width: 1230px;
}
.jst-sector.demo #monitor.size_960 {
  max-width: 960px;
}
.jst-sector.demo #monitor.size_690 {
  max-width: 690px;
}
.jst-sector.demo #monitor.size_320 {
  max-width: 320px;
}
.jst-sector.demo .logo {
  text-align: center;
  margin: -40px auto 0;
  width: 150px;
  position: relative;
}
.jst-sector.demo .logo svg {
  height: 30px;
  animation: logo 5s infinite ease-in-out;
  z-index: 1;
}
.jst-sector.demo .logo:hover svg {
  fill: #1c94d2;
  animation: none;
}
.jst-sector.demo:after {
  content: " ";
  display: block;
  background: url(/img/m_001.png) no-repeat 50% 50%;
  height: 82px;
}
@media (max-width: 1271px) {
  .jst-sector.demo #monitor.size:not(.size_960):not(.size_690):not(.size_320) {
    max-width: 100%;
  }
  .jst-sector.demo .ruler > div.variant_1230:before,
  .jst-sector.demo .ruler > div.variant_1230:after {
    content: "100%";
  }
}
@media (max-width: 1001px) {
  .jst-sector.demo #monitor.size:not(.size_690):not(.size_320) {
    max-width: 100%;
  }
  .jst-sector.demo .ruler > div.variant_960:before,
  .jst-sector.demo .ruler > div.variant_960:after {
    content: "100%";
  }
}
@media (max-width: 731px) {
  .jst-sector.demo #monitor.size:not(.size_320) {
    max-width: 100%;
  }
  .jst-sector.demo .ruler > div.variant_690:before,
  .jst-sector.demo .ruler > div.variant_690:after {
    content: "100%";
  }
}
@media (max-width: 420px) {
  .jst-sector.demo .ruler {
    transition: height 0.5s;
    visibility: hidden;
    height: 0;
    margin-bottom: 0;
  }
}
.jst-sector.footer {
  min-height: unset;
  background: #1d2124;
  color: #999;
}
.jst-sector.footer a {
  color: #999;
}
.jst-sector.footer a:hover {
  color: #fff;
  text-decoration: none;
}
.jst-btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.jst-btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.jst-btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  z-index: 1;
}
.jst-btn-dark.active {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.jst-btn-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.jst-btn-group > .jst-btn {
  position: relative;
  flex: 1 1 auto;
}
.jst-btn-group > .jst-btn:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.jst-btn-group > .jst-btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
